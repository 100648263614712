/*
 * Cleverroute
 * Cleverroute API
 *
 * OpenAPI spec version: 0.0.2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.67
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The UserProfileSettings model module.
 * @module model/UserProfileSettings
 * @version 0.0.2
 */
export default class UserProfileSettings {
  /**
   * Constructs a new <code>UserProfileSettings</code>.
   * @alias module:model/UserProfileSettings
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>UserProfileSettings</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserProfileSettings} obj Optional instance to populate.
   * @return {module:model/UserProfileSettings} The populated <code>UserProfileSettings</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserProfileSettings();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('username'))
        obj.username = ApiClient.convertToType(data['username'], 'String');
      if (data.hasOwnProperty('email'))
        obj.email = ApiClient.convertToType(data['email'], 'String');
      if (data.hasOwnProperty('birthday'))
        obj.birthday = ApiClient.convertToType(data['birthday'], 'Date');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('dateTimeCreated'))
        obj.dateTimeCreated = ApiClient.convertToType(data['dateTimeCreated'], 'Date');
      if (data.hasOwnProperty('street'))
        obj.street = ApiClient.convertToType(data['street'], 'String');
      if (data.hasOwnProperty('location'))
        obj.location = ApiClient.convertToType(data['location'], 'String');
      if (data.hasOwnProperty('zipcode'))
        obj.zipcode = ApiClient.convertToType(data['zipcode'], 'Number');
    }
    return obj;
  }
}

/**
 * unique database ID of the user
 * @member {Number} id
 */
UserProfileSettings.prototype.id = undefined;

/**
 * unique identification name
 * @member {String} username
 */
UserProfileSettings.prototype.username = undefined;

/**
 * unique email of the user
 * @member {String} email
 */
UserProfileSettings.prototype.email = undefined;

/**
 * birthday of the user to select the right oepv ticket for him
 * @member {Date} birthday
 */
UserProfileSettings.prototype.birthday = undefined;

/**
 * full or name part of the user
 * @member {String} name
 */
UserProfileSettings.prototype.name = undefined;

/**
 * time when the user profile was created
 * @member {Date} dateTimeCreated
 */
UserProfileSettings.prototype.dateTimeCreated = undefined;

/**
 * street name and optionally number of the home address of the user
 * @member {String} street
 */
UserProfileSettings.prototype.street = undefined;

/**
 * location name of the home address of the user
 * @member {String} location
 */
UserProfileSettings.prototype.location = undefined;

/**
 * zip-code of the home address of the user
 * @member {Number} zipcode
 */
UserProfileSettings.prototype.zipcode = undefined;

