/*
 * Cleverroute
 * Cleverroute API
 *
 * OpenAPI spec version: 0.0.2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.67
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The UserMetricsSettings model module.
 * @module model/UserMetricsSettings
 * @version 0.0.2
 */
export default class UserMetricsSettings {
  /**
   * Constructs a new <code>UserMetricsSettings</code>.
   * specifies the weight of each metric
   * @alias module:model/UserMetricsSettings
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>UserMetricsSettings</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserMetricsSettings} obj Optional instance to populate.
   * @return {module:model/UserMetricsSettings} The populated <code>UserMetricsSettings</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserMetricsSettings();
      if (data.hasOwnProperty('metricId'))
        obj.metricId = ApiClient.convertToType(data['metricId'], 'String');
      if (data.hasOwnProperty('weightPerc'))
        obj.weightPerc = ApiClient.convertToType(data['weightPerc'], 'Number');
    }
    return obj;
  }
}

/**
 * ID of the metric as string, e.g. co2, time, cost, steps
 * @member {String} metricId
 */
UserMetricsSettings.prototype.metricId = undefined;

/**
 * weight in percantage of this metric compared to the other metrics. They sum up to 100%.
 * @member {Number} weightPerc
 */
UserMetricsSettings.prototype.weightPerc = undefined;

