/*
 * Cleverroute
 * Cleverroute API
 *
 * OpenAPI spec version: 0.0.2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.67
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import RouteTotalAsPoll from './RouteTotalAsPoll';

/**
 * The Poll model module.
 * @module model/Poll
 * @version 0.0.2
 */
export default class Poll {
  /**
   * Constructs a new <code>Poll</code>.
   * @alias module:model/Poll
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>Poll</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Poll} obj Optional instance to populate.
   * @return {module:model/Poll} The populated <code>Poll</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Poll();
      if (data.hasOwnProperty('clientName'))
        obj.clientName = ApiClient.convertToType(data['clientName'], 'String');
      if (data.hasOwnProperty('employeeId'))
        obj.employeeId = ApiClient.convertToType(data['employeeId'], 'String');
      if (data.hasOwnProperty('employeeRegionalCode'))
        obj.employeeRegionalCode = ApiClient.convertToType(data['employeeRegionalCode'], 'Number');
      if (data.hasOwnProperty('destination'))
        obj.destination = ApiClient.convertToType(data['destination'], 'String');
      if (data.hasOwnProperty('monthsInOrg'))
        obj.monthsInOrg = ApiClient.convertToType(data['monthsInOrg'], 'Number');
      if (data.hasOwnProperty('monthsInOrgMax'))
        obj.monthsInOrgMax = ApiClient.convertToType(data['monthsInOrgMax'], 'Number');
      if (data.hasOwnProperty('workdaysPerWeek'))
        obj.workdaysPerWeek = ApiClient.convertToType(data['workdaysPerWeek'], 'Number');
      if (data.hasOwnProperty('workdaysPerWeekMax'))
        obj.workdaysPerWeekMax = ApiClient.convertToType(data['workdaysPerWeekMax'], 'Number');
      if (data.hasOwnProperty('homeofficeDaysPerWeekMax'))
        obj.homeofficeDaysPerWeekMax = ApiClient.convertToType(data['homeofficeDaysPerWeekMax'], 'Number');
      if (data.hasOwnProperty('homeofficeDaysPerWeek'))
        obj.homeofficeDaysPerWeek = ApiClient.convertToType(data['homeofficeDaysPerWeek'], 'Number');
      if (data.hasOwnProperty('plz'))
        obj.plz = ApiClient.convertToType(data['plz'], 'String');
      if (data.hasOwnProperty('pollType'))
        obj.pollType = ApiClient.convertToType(data['pollType'], 'String');
      if (data.hasOwnProperty('routes'))
        obj.routes = ApiClient.convertToType(data['routes'], [RouteTotalAsPoll]);
      if (data.hasOwnProperty('pollDate'))
        obj.pollDate = ApiClient.convertToType(data['pollDate'], 'Date');
      if (data.hasOwnProperty('monthsInUniversity'))
        obj.monthsInUniversity = ApiClient.convertToType(data['monthsInUniversity'], 'Number');
      if (data.hasOwnProperty('monthsInUniversityMax'))
        obj.monthsInUniversityMax = ApiClient.convertToType(data['monthsInUniversityMax'], 'Number');
      if (data.hasOwnProperty('monthsLecturePeriod'))
        obj.monthsLecturePeriod = ApiClient.convertToType(data['monthsLecturePeriod'], 'Number');
      if (data.hasOwnProperty('monthsLecturePeriodMax'))
        obj.monthsLecturePeriodMax = ApiClient.convertToType(data['monthsLecturePeriodMax'], 'Number');
      if (data.hasOwnProperty('monthsLectureFreePeriod'))
        obj.monthsLectureFreePeriod = ApiClient.convertToType(data['monthsLectureFreePeriod'], 'Number');
      if (data.hasOwnProperty('monthsLectureFreePeriodMax'))
        obj.monthsLectureFreePeriodMax = ApiClient.convertToType(data['monthsLectureFreePeriodMax'], 'Number');
      if (data.hasOwnProperty('presenceDaysInLecturePeriod'))
        obj.presenceDaysInLecturePeriod = ApiClient.convertToType(data['presenceDaysInLecturePeriod'], 'Number');
      if (data.hasOwnProperty('presenceDaysInLecturePeriodMax'))
        obj.presenceDaysInLecturePeriodMax = ApiClient.convertToType(data['presenceDaysInLecturePeriodMax'], 'Number');
      if (data.hasOwnProperty('presenceDaysInLectureFreePeriod'))
        obj.presenceDaysInLectureFreePeriod = ApiClient.convertToType(data['presenceDaysInLectureFreePeriod'], 'Number');
      if (data.hasOwnProperty('presenceDaysInLectureFreePeriodMax'))
        obj.presenceDaysInLectureFreePeriodMax = ApiClient.convertToType(data['presenceDaysInLectureFreePeriodMax'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {String} clientName
 */
Poll.prototype.clientName = undefined;

/**
 * @member {String} employeeId
 */
Poll.prototype.employeeId = undefined;

/**
 * @member {Number} employeeRegionalCode
 */
Poll.prototype.employeeRegionalCode = undefined;

/**
 * chosen destination name
 * @member {String} destination
 */
Poll.prototype.destination = undefined;

/**
 * @member {Number} monthsInOrg
 */
Poll.prototype.monthsInOrg = undefined;

/**
 * @member {Number} monthsInOrgMax
 */
Poll.prototype.monthsInOrgMax = undefined;

/**
 * @member {Number} workdaysPerWeek
 */
Poll.prototype.workdaysPerWeek = undefined;

/**
 * @member {Number} workdaysPerWeekMax
 */
Poll.prototype.workdaysPerWeekMax = undefined;

/**
 * @member {Number} homeofficeDaysPerWeekMax
 */
Poll.prototype.homeofficeDaysPerWeekMax = undefined;

/**
 * @member {Number} homeofficeDaysPerWeek
 */
Poll.prototype.homeofficeDaysPerWeek = undefined;

/**
 * regional code of the user
 * @member {String} plz
 */
Poll.prototype.plz = undefined;

/**
 * Allowed values for the <code>pollType</code> property.
 * @enum {String}
 * @readonly
 */
Poll.PollTypeEnum = {
  /**
   * value: "company"
   * @const
   */
  company: "company",

  /**
   * value: "event"
   * @const
   */
  event: "event"
};
/**
 * poll types supported who have different possibilities to poll like yes/no or frequency of transport medium usage
 * @member {module:model/Poll.PollTypeEnum} pollType
 */
Poll.prototype.pollType = undefined;

/**
 * @member {Array.<module:model/RouteTotalAsPoll>} routes
 */
Poll.prototype.routes = undefined;

/**
 * date of poll start
 * @member {Date} pollDate
 */
Poll.prototype.pollDate = undefined;

/**
 * @member {Number} monthsInUniversity
 */
Poll.prototype.monthsInUniversity = undefined;

/**
 * @member {Number} monthsInUniversityMax
 */
Poll.prototype.monthsInUniversityMax = undefined;

/**
 * @member {Number} monthsLecturePeriod
 */
Poll.prototype.monthsLecturePeriod = undefined;

/**
 * @member {Number} monthsLecturePeriodMax
 */
Poll.prototype.monthsLecturePeriodMax = undefined;

/**
 * @member {Number} monthsLectureFreePeriod
 */
Poll.prototype.monthsLectureFreePeriod = undefined;

/**
 * @member {Number} monthsLectureFreePeriodMax
 */
Poll.prototype.monthsLectureFreePeriodMax = undefined;

/**
 * @member {Number} presenceDaysInLecturePeriod
 */
Poll.prototype.presenceDaysInLecturePeriod = undefined;

/**
 * @member {Number} presenceDaysInLecturePeriodMax
 */
Poll.prototype.presenceDaysInLecturePeriodMax = undefined;

/**
 * @member {Number} presenceDaysInLectureFreePeriod
 */
Poll.prototype.presenceDaysInLectureFreePeriod = undefined;

/**
 * @member {Number} presenceDaysInLectureFreePeriodMax
 */
Poll.prototype.presenceDaysInLectureFreePeriodMax = undefined;

