/*
 * Cleverroute
 * Cleverroute API
 *
 * OpenAPI spec version: 0.0.2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.67
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import OneWayRoute from './OneWayRoute';
import RouteMetrics from './RouteMetrics';
import RouteTotal from './RouteTotal';

/**
 * The RouteTotalAsPoll model module.
 * @module model/RouteTotalAsPoll
 * @version 0.0.2
 */
export default class RouteTotalAsPoll extends RouteTotal {
  /**
   * Constructs a new <code>RouteTotalAsPoll</code>.
   * Inherits RouteTotal with additional properties concerning a poll
   * @alias module:model/RouteTotalAsPoll
   * @class
   * @extends module:model/RouteTotal
   */
  constructor() {
    super();
  }

  /**
   * Constructs a <code>RouteTotalAsPoll</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RouteTotalAsPoll} obj Optional instance to populate.
   * @return {module:model/RouteTotalAsPoll} The populated <code>RouteTotalAsPoll</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RouteTotalAsPoll();
      RouteTotal.constructFromObject(data, obj);
      if (data.hasOwnProperty('frequencyType'))
        obj.frequencyType = ApiClient.convertToType(data['frequencyType'], 'String');
      if (data.hasOwnProperty('frequency'))
        obj.frequency = ApiClient.convertToType(data['frequency'], 'Number');
      if (data.hasOwnProperty('nPassengers'))
        obj.nPassengers = ApiClient.convertToType(data['nPassengers'], 'Number');
      if (data.hasOwnProperty('nStars'))
        obj.nStars = ApiClient.convertToType(data['nStars'], 'Number');
      if (data.hasOwnProperty('transportUsed'))
        obj.transportUsed = ApiClient.convertToType(data['transportUsed'], 'Boolean');
    }
    return obj;
  }
}

/**
 * Allowed values for the <code>frequencyType</code> property.
 * @enum {String}
 * @readonly
 */
RouteTotalAsPoll.FrequencyTypeEnum = {
  /**
   * value: "days"
   * @const
   */
  days: "days",

  /**
   * value: "percentage"
   * @const
   */
  percentage: "percentage"
};
/**
 * type of frequency of usage of the transport-type. 'days' stands for days per week the transport-type is used. 'percentage' stands for percentage per year the transport-type is used.
 * @member {module:model/RouteTotalAsPoll.FrequencyTypeEnum} frequencyType
 */
RouteTotalAsPoll.prototype.frequencyType = undefined;

/**
 * used only for poll
 * @member {Number} frequency
 */
RouteTotalAsPoll.prototype.frequency = undefined;

/**
 * number of passengers who share the transport medium, specifically car
 * @member {Number} nPassengers
 */
RouteTotalAsPoll.prototype.nPassengers = undefined;

/**
 * number of stars the user evaluates the route
 * @member {Number} nStars
 */
RouteTotalAsPoll.prototype.nStars = undefined;

/**
 * whether the transport medium is used or not
 * @member {Boolean} transportUsed
 */
RouteTotalAsPoll.prototype.transportUsed = undefined;

