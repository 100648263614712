<template>
  <v-container>
    <div class="mx-4 mx-md-0">
      <!-- to align with the margin of Searchbar on mobile -->
      <v-data-iterator
        :items="routes"
        :items-per-page="100"
        hide-default-footer
        :sort-by="sortByValueGetter"
        :custom-sort="sortTransports"
      >
        <template v-slot:header>
          <v-row no-gutters align="end" class="my-4 d-print-none">
            <v-col
              :cols="printView ? undefined : usePoll ? 3 : 4"
              class="d-flex align-center my-0 my-md-3"
            >
              <div>
                <i
                  >Alle Werte gelten für die einfache Strecke.<br />Genug
                  Stauzeit berücksichtigt? Manuell anpassen?</i
                >
              </div>
            </v-col>
            <v-col :cols="usePoll ? 7 : 8">
              <div class="mr-2 text-body-1">
                <v-icon class="mr-1">format_line_spacing</v-icon>
                Sortieren nach:
              </div>
              <v-btn-toggle
                :value="sortByValueGetter"
                color="primary"
                @change="sortByValueChanged($event)"
                background-color="secondary"
                mandatory
                :dense="$vuetify.breakpoint.xs"
                class="rounded-tr-xl sort-btns full-width"
              >
                <v-tooltip bottom v-for="x in sortByOptions" :key="x.id">
                  <template v-slot:activator="{on, attrs}">
                    <v-btn
                      :value="x.id"
                      v-bind="attrs"
                      :color="x.id === 'sortByValueGetter' ? 'primary' : ''"
                      v-on="on"
                      text
                      class="col-auto col-lg-3"
                      :x-large="$vuetify.breakpoint.lgAndUp"
                    >
                      <v-icon
                        :color="x.id === 'sortByValueGetter' ? 'primary' : ''"
                        class="mr-sm-1"
                        >{{ x.icon }}</v-icon
                      >
                      <span class="d-none d-sm-inline">{{ x.name }}</span>
                    </v-btn>
                  </template>
                  <span>Sortieren nach {{ x.name }}</span>
                </v-tooltip>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </template>

        <template v-slot:default="props">
          <v-row v-for="(routing, i) in props.items" :key="routingKey(routing)">
            <v-col :cols="usePoll ? 10 : 12">
              <Transport
                :t="routing"
                :searchParams="searchParams"
                :rideshareHost="rideshareHost"
                :reservationToolHost="reservationToolHost"
                :printView="printView"
                :onlyJourneyThere="onlyJourneyThere"
                :loading="loading"
                :percentageTransportsComputed="percentageTransportsComputed"
              />
            </v-col>
            <v-col v-if="usePoll && pollType == 'company'" cols="2">
              <PollCompanyInputForm :transportId="routing.transport.id" :transportMode="routing.transport.routing.mode" :config="config" :routes="routes" />
            </v-col>
            <v-col v-else-if="usePoll && pollType == 'event'" cols="2">
              <PollEventInputForm :transportId="routing.transport.id"  :transportMode="routing.transport.routing.mode" :config="config" :routes="routes" />
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
      <v-row v-if="usePoll" justify="center" class="my-8">
        <v-col cols="12" style="align-items: center">
          <PollFooter :config="config" :destination="searchParams.destination" :routes="routes" />
        </v-col>
      </v-row>
      <v-row v-if="showMoreRoutesRow" justify="start" class="my-8">
        <v-col cols="12">
          <div
            class="flex-box-centered-horizontal border-radius-10px padding-10px background-white"
          >
            <div v-if="showMoreRoutesHint">
              <a
                @click="showMoreRoutes()"
                class="cursor-pointer font-size-22px margin-left-15px"
                >Kombinationen anzeigen</a
              >
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}"
                  ><v-icon
                    color="primary"
                    size="dense"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    >mdi-help-circle</v-icon
                  ></template
                >
                <span>z.B. ÖPV + Taxi, ÖPV + Car-Sharing</span>
              </v-tooltip>
              <v-icon aria-hidden="false"> arrow_downward </v-icon>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" class="my-8">
        <div class="text-h6 font-weight-light d-flex flex-column align-center">
          <a @click="print">
            <v-icon color="primary">print</v-icon>
            Druckansicht
          </a>
        </div>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import PollFooter from "./poll/PollFooter.vue";

import {wait} from "@norwin/javascript-is-bad";
import Vue from "vue";
import {metricsDisplayParams} from "../ui-mappings";
import Transport from "./Transport.vue";
import {storeToRefs} from "pinia";
import {usePollStore} from "../store/poll-store";
import * as utils from "../utils";
import PollCompanyInputForm from "./poll/PollCompanyInputForm.vue";
import PollEventInputForm from "./poll/PollEventInputForm.vue";
import { useConfigStore } from "../store/config-store";

export default Vue.extend({
  name: "TransportListing",

  components: {
    PollCompanyInputForm,
    PollEventInputForm,
    Transport,
    PollFooter,
  },

  props: {
    config: {type: Object, default: undefined},
    searchParams: {
      type: Object,
      default: () => ({
        origin: "",
        departure: "", // contains Date instances when filled out
        arrival: "",
        destination: "",
        includeIntermodal: false,
        minutesCongestion: "0",
        minutesParking: "0",
        originLonLat: "",
        destinationLonLat: "",
      }),
    },
    routes: {type: Array, default: () => []},
    rideshareHost: String,
    reservationToolHost: String,
    onlyJourneyThere: Boolean,
    showMoreRoutesHint: Boolean,
    showMoreRoutesClicked: {type: Function},
    loading: {type: Boolean, default: false},
    percentageTransportsComputed: {type: Number, default: 0},
  },

  data: () => ({
    metricToSortRoutesBy: {value: "time"},
    showProgressCircluar: false,
    sortByOptions: metricsDisplayParams,
    lastUpdated: null,
    printView: false,
    submitText: "",
  }),

  mounted() {
    this.metricToSortRoutesBy = storeToRefs(useConfigStore()).metricToSortRoutesBy;
  },

  methods: {
    showMoreRoutes() {
      this.showProgressCircluar = true;
      this.showMoreRoutesClicked();
    },
    async print() {
      this.printView = !this.printView;
      if (this.printView) {
        await wait(2000); // give time for map tiles to load
        window.print();
        this.printView = false;
      }
    },
    sortByValueChanged(sortByValue) {
      useConfigStore().setMetricToSortRoutesBy(sortByValue);
    },
    routingKey(r) {
      // used to force rerender of routes
      return `${r.transport.id}`;
    },

    sortTransports(transports) {
      const key = this.sortByValueGetter; //this.sortBy;
      const descending = ["sport"].includes(key);
      return transports.sort((a, b) => {
        let valA = a.metrics[key];
        let valB = b.metrics[key];
        if (descending) {
          valA *= -1;
          valB *= -1;
        }

        return valA - valB;
      });
    },
  },

  computed: {
    usePoll() {
      return storeToRefs(usePollStore()).usePoll.value || false;
    },
    pollType() {
      return storeToRefs(usePollStore()).pollType.value || "company";
    },
    showMoreRoutesRow: {
      get: function () {
        return this.showMoreRoutesHint;
      },
    },
    sortByValueGetter: {
      get: function () {
        return this.metricToSortRoutesBy.value;
      },
    },
    colorOfInputElementsStyle() {
      return utils.colorOfInputElementsStyle.bind(this)();
    },
  },

  watch: {
  },
});
</script>

<style scoped>
.full-width {
  width: 100%;
}
.sort-btns >>> button:not(.v-btn--active),
.sort-btns >>> i,
button {
  color: #333 !important;
}
.background-white {
  background-color: white;
}
.padding-10px {
  padding: 10px;
}
.border-radius-10px {
  border-radius: 10px;
}
.flex-box-centered-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.font-size-22px {
  font-size: 22px;
}
.margin-left-15px {
  margin-left: 15px;
}
</style>
