/*
 * Cleverroute
 * Cleverroute API
 *
 * OpenAPI spec version: 0.0.2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.67
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The RouteLeg model module.
 * @module model/RouteLeg
 * @version 0.0.2
 */
export default class RouteLeg {
  /**
   * Constructs a new <code>RouteLeg</code>.
   * @alias module:model/RouteLeg
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>RouteLeg</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RouteLeg} obj Optional instance to populate.
   * @return {module:model/RouteLeg} The populated <code>RouteLeg</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new RouteLeg();
      if (data.hasOwnProperty('transport'))
        obj.transport = ApiClient.convertToType(data['transport'], 'String');
      if (data.hasOwnProperty('arrival'))
        obj.arrival = ApiClient.convertToType(data['arrival'], 'Date');
      if (data.hasOwnProperty('departure'))
        obj.departure = ApiClient.convertToType(data['departure'], 'Date');
      if (data.hasOwnProperty('mode'))
        obj.mode = ApiClient.convertToType(data['mode'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('details'))
        obj.details = ApiClient.convertToType(data['details'], 'String');
      if (data.hasOwnProperty('geometry'))
        obj.geometry = ApiClient.convertToType(data['geometry'], 'String');
      if (data.hasOwnProperty('distanceKm'))
        obj.distanceKm = ApiClient.convertToType(data['distanceKm'], 'Number');
      if (data.hasOwnProperty('costCents'))
        obj.costCents = ApiClient.convertToType(data['costCents'], 'Number');
      if (data.hasOwnProperty('costPart'))
        obj.costPart = ApiClient.convertToType(data['costPart'], 'Number');
      if (data.hasOwnProperty('verbundNameShort'))
        obj.verbundNameShort = ApiClient.convertToType(data['verbundNameShort'], 'String');
      if (data.hasOwnProperty('includedInDTicket'))
        obj.includedInDTicket = ApiClient.convertToType(data['includedInDTicket'], 'Boolean');
      if (data.hasOwnProperty('direction'))
        obj.direction = ApiClient.convertToType(data['direction'], 'String');
      if (data.hasOwnProperty('arrivalPlatform'))
        obj.arrivalPlatform = ApiClient.convertToType(data['arrivalPlatform'], 'String');
      if (data.hasOwnProperty('departurePlatform'))
        obj.departurePlatform = ApiClient.convertToType(data['departurePlatform'], 'String');
      if (data.hasOwnProperty('co2'))
        obj.co2 = ApiClient.convertToType(data['co2'], 'Number');
      if (data.hasOwnProperty('transportType'))
        obj.transportType = ApiClient.convertToType(data['transportType'], 'String');
      if (data.hasOwnProperty('transportName'))
        obj.transportName = ApiClient.convertToType(data['transportName'], 'String');
      if (data.hasOwnProperty('durationM'))
        obj.durationM = ApiClient.convertToType(data['durationM'], 'Number');
      if (data.hasOwnProperty('sharingStationalCost'))
        obj.sharingStationalCost = ApiClient.convertToType(data['sharingStationalCost'], 'Boolean');
      if (data.hasOwnProperty('sharingActivationCost'))
        obj.sharingActivationCost = ApiClient.convertToType(data['sharingActivationCost'], 'Boolean');
    }
    return obj;
  }
}

/**
 * transport-ID
 * @member {String} transport
 */
RouteLeg.prototype.transport = undefined;

/**
 * @member {Date} arrival
 */
RouteLeg.prototype.arrival = undefined;

/**
 * @member {Date} departure
 */
RouteLeg.prototype.departure = undefined;

/**
 * e.g. \"car\", \"train\"
 * @member {String} mode
 */
RouteLeg.prototype.mode = undefined;

/**
 * description as path info like 'Stuttgart HBF -> Frankfurt HBF'
 * @member {String} description
 */
RouteLeg.prototype.description = undefined;

/**
 * @member {String} details
 */
RouteLeg.prototype.details = undefined;

/**
 * geojson of the leg
 * @member {String} geometry
 */
RouteLeg.prototype.geometry = undefined;

/**
 * @member {Number} distanceKm
 */
RouteLeg.prototype.distanceKm = undefined;

/**
 * cost of leg in cent
 * @member {Number} costCents
 */
RouteLeg.prototype.costCents = undefined;

/**
 * part-number to which this leg belongs i.e. part-number 0, 1 ...
 * @member {Number} costPart
 */
RouteLeg.prototype.costPart = undefined;

/**
 * like 'vbn'
 * @member {String} verbundNameShort
 */
RouteLeg.prototype.verbundNameShort = undefined;

/**
 * whether this leg can be used with the Deutschlandticket (which is the case with regional/short-distance oepv-modes like Bus, Subway)
 * @member {Boolean} includedInDTicket
 */
RouteLeg.prototype.includedInDTicket = undefined;

/**
 * direction of the leg e.g. if the train drives in the direction of Berlin HBF, then it shows that
 * @member {String} direction
 */
RouteLeg.prototype.direction = undefined;

/**
 * @member {String} arrivalPlatform
 */
RouteLeg.prototype.arrivalPlatform = undefined;

/**
 * @member {String} departurePlatform
 */
RouteLeg.prototype.departurePlatform = undefined;

/**
 * @member {Number} co2
 */
RouteLeg.prototype.co2 = undefined;

/**
 * @member {String} transportType
 */
RouteLeg.prototype.transportType = undefined;

/**
 * @member {String} transportName
 */
RouteLeg.prototype.transportName = undefined;

/**
 * @member {Number} durationM
 */
RouteLeg.prototype.durationM = undefined;

/**
 * @member {Boolean} sharingStationalCost
 */
RouteLeg.prototype.sharingStationalCost = undefined;

/**
 * @member {Boolean} sharingActivationCost
 */
RouteLeg.prototype.sharingActivationCost = undefined;

