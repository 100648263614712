/*
 * Cleverroute
 * Cleverroute API
 *
 * OpenAPI spec version: 0.0.2
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.67
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The VehicleSpec model module.
 * @module model/VehicleSpec
 * @version 0.0.2
 */
export default class VehicleSpec {
  /**
   * Constructs a new <code>VehicleSpec</code>.
   * @alias module:model/VehicleSpec
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>VehicleSpec</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/VehicleSpec} obj Optional instance to populate.
   * @return {module:model/VehicleSpec} The populated <code>VehicleSpec</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new VehicleSpec();
      if (data.hasOwnProperty('ownVehicleId'))
        obj.ownVehicleId = ApiClient.convertToType(data['ownVehicleId'], 'String');
      if (data.hasOwnProperty('vehicleClass'))
        obj.vehicleClass = ApiClient.convertToType(data['vehicleClass'], 'String');
      if (data.hasOwnProperty('consumption'))
        obj.consumption = ApiClient.convertToType(data['consumption'], 'Number');
      if (data.hasOwnProperty('fuelType'))
        obj.fuelType = ApiClient.convertToType(data['fuelType'], 'String');
      if (data.hasOwnProperty('roadPerformance'))
        obj.roadPerformance = ApiClient.convertToType(data['roadPerformance'], 'Number');
    }
    return obj;
  }
}

/**
 * id of the vehicle, e.g. car, bicycle, scooter
 * @member {String} ownVehicleId
 */
VehicleSpec.prototype.ownVehicleId = undefined;

/**
 * Allowed values for the <code>vehicleClass</code> property.
 * @enum {String}
 * @readonly
 */
VehicleSpec.VehicleClassEnum = {
  /**
   * value: "low"
   * @const
   */
  low: "low",

  /**
   * value: "middle"
   * @const
   */
  middle: "middle",

  /**
   * value: "high"
   * @const
   */
  high: "high"
};
/**
 * class of the vehicle to specify its worth
 * @member {module:model/VehicleSpec.VehicleClassEnum} vehicleClass
 */
VehicleSpec.prototype.vehicleClass = undefined;

/**
 * consumption per 100 km. depending on the fueltype it can be litres for cars with fuel or kWh for electric cars
 * @member {Number} consumption
 */
VehicleSpec.prototype.consumption = undefined;

/**
 * Allowed values for the <code>fuelType</code> property.
 * @enum {String}
 * @readonly
 */
VehicleSpec.FuelTypeEnum = {
  /**
   * value: "bencine"
   * @const
   */
  bencine: "bencine",

  /**
   * value: "diesel"
   * @const
   */
  diesel: "diesel",

  /**
   * value: "electric"
   * @const
   */
  electric: "electric",

  /**
   * value: "lpg"
   * @const
   */
  lpg: "lpg",

  /**
   * value: "else"
   * @const
   */
  _else: "else"
};
/**
 * fuel type of the vehicle. If it's bicycle then 'else' is selected
 * @member {module:model/VehicleSpec.FuelTypeEnum} fuelType
 */
VehicleSpec.prototype.fuelType = undefined;

/**
 * how many km it has on its speedometer
 * @member {Number} roadPerformance
 */
VehicleSpec.prototype.roadPerformance = undefined;

